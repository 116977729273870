<style scoped lang="scss">
.goodInfo {
  margin-top: 20px;
  position: relative;
  border: 1px solid #dee0e1;
  background: #ffffff;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-flow: row;
  .title {
    display: flex;
    align-items: center;
    height: 80px;
    font-size: 18px;
    font-family: PingFang SC-中粗体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    padding: 0 20px;
    grid-column-start: 1;
    grid-column-end: 3;
    position: relative;
    border-bottom: 1px solid #dee0e1;
    cursor: pointer;
    span {
      font-weight: bold;
      padding: 0 10px;
    }
    .ziyinglogo {
      width: 34px;
      height: 18px;
      margin-right: 10px;
    }
    .icon {
      width: 16px;
      height: 16px;
      color: #7a8087;
    }
    .sixin {
      width: 129px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #b1b1b1;
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #7f7f7f;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 20px;
    }
  }
  .good_card_list {
    padding: 0 20px;
    position: relative;
    border-right: 1px solid #dee0e1;
    .boom-box {
      padding: 20px 0;
      border-top: 1px solid #e8e8e8;
      &:first-of-type {
        border: 0;
      }
    }
    .good_card {
      width: 100%;
      height: auto;
      position: relative;
      display: flex;
      &.type2 {
        margin-bottom: 0;
      }
      .img {
        width: 66px;
        height: 66px;
        border-radius: 4px;
        opacity: 1;
        border: 1px solid #dee0e1;
        cursor: pointer;
        img {
          min-width: 100%;
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
      &.gift {
        .img {
          width: 66px;
          height: 66px;
          border-radius: 4px;
          opacity: 1;
          text-align: right;
          border: 0;
          img {
            min-width: 56px;
            width: 56px;
            height: 56px;
            border-radius: 4px;
          }
        }
      }
      .info {
        width: calc(100% - 66px);
        margin-left: 10px;
        display: flex;
        // flex-direction: column;
        .cont {
          width: calc(100% - 70px);
          position: relative;
          cursor: pointer;

          strong {
            font-size: 16px;
            font-family: PingFang SC-常规体, PingFang SC;
            font-weight: normal;
            color: #1a1a1a;
            line-height: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            .yushou {
              display: inline-block;
              width: 31px;
              height: 18px;
              background: url("~@assets/images/orderlist/yushou.png") no-repeat;
              background-size: contain;
              transform: translateY(3px);

            }
            em {
              display: inline-block;
              width: 31px;
              height: 18px;
              background: linear-gradient(
                90deg,
                rgba(239, 136, 51, 1),
                rgba(236, 98, 48, 1)
              );
              border-radius: 8px;
              text-align: center;
              font-size: 11px;
              margin: 0;
              i {
                display: inline-block;
                font-size: 11px;
                line-height: 18px;
                color: #fff;
                font-weight: 600;
                // transform: scale(0.83);
                font-style: normal;
              }
            }
          }
          .tedian {
            // transform: translateY(6px);
            width: 34px;
            height: 18px;
            border-radius: 2px;
            border: 1px solid #f59907;
            font-size: 12px;
            text-align: center;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #f59907;
            line-height: 18px;
            display: inline-block;
          }
          p {
            margin: 0;
            padding-top: 6px;
          }
          > p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7f7f7f;
            line-height: 14px;
          }
          em {
            margin-top: 4px;
          }
          .time {
            display: inline-block;
            margin-top: 8px;
            color: #f5a728;
            font-size: 14px;
          }
        }
        .price {
          width: 70px;
          p {
            margin: 0;
            padding: 0;
            text-align: right;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            line-height: 14px;
          }
          .money {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1a1a1a;
            line-height: 16px;
            margin-top: 4px;
          }
          .gray {
            margin-top: 8px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #7F7F7F;
            line-height: 16px;
            text-align: right;
            &:nth-of-type(1) {
              margin-top: 18px;
            }
          }
        }
      }
      &.gift {
        margin-top: 30px;
      }
    }
    .refund_btn {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: right;
      .refund_btn-btn {
        width: 78px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #b1b1b1;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 30px;
        color: #7f7f7f;
        cursor: pointer;
      }
    }
    .steps-box {
      margin-top: 18px;
      background: #f8f8f8;
      border-radius: 4px;
      .tips {
        width: 100%;
        height: 34px;
        background: #fff8ee;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f5a728;
        padding-left: 20px;
        border-radius: 4px 4px 0 0;
        .iconfont {
          margin-right: 5px;
          width: 16px;
          height: 16px;
          background: url("~@assets/images/orderlist/details-card.png")
            no-repeat;
          background-size: contain;
        }
      }
      .steps {
        width: 100%;
        margin: 0 auto;
        margin-left: 0px;
        padding: 20px 20px 10px 40px;
        position: relative;
        .steps-li{
          &:nth-of-type(2){
            margin-top: -6px;
          }
          &:nth-of-type(3){
            margin-top: 8px;
          }
        }
        .steps-line {
          position: absolute;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          left: 20px;
          top: 25px;
          .top,
          .bottom {
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background: #b1b1b1;
          }
          .red {
            background: #ff3d20;
          }
          .line {
            margin: 4px 0;
            width: 2px;
            height: 34px;
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 4.1px 0;
            background: linear-gradient(
              0deg,
              rgba(177, 177, 177, 1) 0%,
              rgba(177, 177, 177, 1) 100%
            );
            span {
              display: block;
              width: 2px;
              background: #f8f8f8;
              height: 3px;
            }
            &.line1 {
              background: linear-gradient(
                180deg,
                rgba(255, 61, 32, 1) 0%,
                rgba(177, 177, 177, 1) 100%
              );
            }
            &.line2 {
              background: linear-gradient(
                0deg,
                rgba(255, 61, 32, 1) 0%,
                rgba(177, 177, 177, 1) 100%
              );
            }
          }
        }
        .steps-li {
          height: 46px;
          &:last-of-type {
            border: 0;
            margin-bottom: 0;
            height: max-content;
          }
          .steps-p {
            // height: 36px;
            color: #1f1f1f;
            font-weight: normal;
            line-height: 24px;
            font-size: 16px;
            // display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            position: relative;
            font-weight: 500;
            font-family: PingFangSC-Regular, PingFang SC;
            .step_title {
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
            }
            &.red {
              color: #ff3d20;
            }
            &.nono{
              color: #7F7F7F;
            }
            .steps-time {
              margin-top: 5px;
              font-size: 12px;
              font-family: PingFang SC-常规体, PingFang SC;
              font-weight: normal;
              color: #7f7f7f;
              line-height: 12px;
            }
            .right-price {
              height: 24px;
              font-size: 16px;
              font-family: Avenir-Heavy, Avenir;
              font-weight: 800;
              color: #1a1a1a;
              line-height: 24px;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          .steps-price {
            margin-top: -10px;
            display: flex;
            justify-content: space-between;
            height: 14px;
            font-size: 14px;
            font-weight: normal;
            color: #7f7f7f;
            margin-bottom: 20px;
          }
          .steps-price2 {
            display: flex;
            justify-content: space-between;
            height: 42px;
            font-size: 30px;
            font-weight: bold;
            color: #222c37;
            line-height: 42px;
            .price2 {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
  .freight-box {
    padding-bottom: 17px;
  }
  .freight {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 24px;
    margin-top: 12px;
    .copyp {
      display: flex;
      align-items: center;
      .fuzhi {
        margin-left: 5px;
        min-width: 40px;
        height: 28px;
      }
    }
    &:nth-of-type(1) {
      margin-top: 20px;
    }
    span {
      &:nth-of-type(2) {
        font-weight: 400;
        color: #1a1a1a;
      }
      &.price2 {
        font-size: 20px;
        font-weight: bolder;
        color: #ff3535;
      }
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
    padding: 0 20px;
    > span {
      margin-left: 16px;
      font-size: 20px;
      font-family: Avenir-Heavy, Avenir;
      font-weight: 800;
      color: #ff3535;
      .ico {
        font-size: 20px;
      }
    }
  }
  .service {
    margin-top: 40px;
    display: block;
    text-align: center;
    color: #00cba6;
    font-weight: normal;
    line-height: 88px;
    height: 88px;
    width: calc(100% + 60px);
    margin-left: -0.4rem;
    border-top: 2px solid #f2f2f2;
  }
  .openbox-result {
    margin-top: 20px;
    padding: 30px;
    background: #f8f8f8;
    border-radius: 8px;
    width: 100%;
    .openbox-title {
      display: flex;
      justify-content: space-between;
      height: 28px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1a1a1a;
      line-height: 28px;
      .num {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 28px;
        display: flex;
        align-items: center;
        .iconfont {
          width: 24px;
          height: 24px;
          color: #1a1a1a;
        }
      }
    }
    .openbox-ul {
      margin-top: 30px;
      display: flex;
      .openbox-li {
        position: relative;
        margin-left: 16px;
        &:first-child {
          margin-left: 0;
        }
        .unpacking_type {
          position: absolute;
          top: 0px;
          right: 0;
          width: 82px;
          height: 34px;
          background: #00c4a1;
          line-height: 34px;
          text-align: center;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 400;
          z-index: 1;
          color: #f8f8f8;
          border-radius: 0px 8px 0px 8px;
          &.unpacking_type1 {
            display: block;
          }
        }
        .img {
          position: relative;
          width: 200px;
          height: 280px;
          border-radius: 8px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
          .more {
            width: 100%;
            height: 100%;
            background: rgba(000, 000, 000, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .name {
          margin-top: 10px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
        }
      }
    }
  }
  .remark {
    margin-top: 17px;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dee0e1;
    .left {
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #1a1a1a;
      line-height: 24px;
    }
    .remarktext {
      width: 210px;
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #1a1a1a;
      line-height: 24px;
    }
  }
  .order_paper {
    border-top: 1px solid #dee0e1;
  }
}
</style>

<template>
  <div class="goodInfo">
    <div class="title" v-if="orderInfo.shop" @click.stop="goShop()">
      <img class="icon" src="~@assets/images/orderlist/orderlistshop.png" />
      <span>{{ orderInfo.shop.name }}</span>
      <img
        class="ziyinglogo"
        v-if="orderInfo.shop.official != '0'"
        src="~@assets/images/orderlist/ziyinglogo.png"
      />
      <!-- <div class="sixin">私信发起者</div> -->
    </div>
    <div class="good_card_list" v-if="orderInfo.shop">
      <div
        class="boom-box"
        v-for="(item, index) of orderInfo.shop.skus"
        :key="index"
        @click.stop="goGoods(item.product_id)"
      >
        <div class="good_card-box">
          <div
            :class="['good_card', orderInfo.presale_type == 2 ? 'type2' : '']"
          >
            <div class="img">
              <img
                :src="this.$imgUrlFilter(item.product_img, this.imgWidth)"
                alt=""
              />
            </div>
            <div class="info">
              <div class="cont">
                <strong>
                  <span class="yushou" v-if="orderInfo.presale_type != 0">
                  </span>
                  {{ item.product_name }}
                </strong>
                <p>{{ item.sku_specs }}</p>
                <em class="time">{{ item.deliver_forecast_time }}</em>
              </div>
              <!-- 价格数量 -->
              <div class="price" v-if="orderInfo.presale_type == 2">
                <p class="money" style="border: 0">¥{{ item.sku_price }}</p>
                <p class="gray">× {{ item.num }}</p>
              </div>
              <div class="price" v-else>
                <p class="text">总实付</p>
                <p class="money">¥{{ item.total_amount }}</p>
                <div class="gray">¥{{ item.sku_price }}</div>
                <div class="gray">× {{ item.num }}</div>
              </div>
            </div>
          </div>
          <!-- <div
            v-if="item.sku_btns && item.sku_btns.length > 0"
            class="refund_btn"
          >
            <div
              class="refund_btn-btn"
              @click.stop="
                op(item.sku_btns[0].type, item.sku_order_id, item.refund_id)
              "
            >
              {{ item.sku_btns[0].title }}
            </div>
          </div> -->
          <template v-if="item.gifts && item.gifts.length">
            <div
              class="good_card gift"
              v-for="(item, ix) in item.gifts"
              :key="ix"
              style="margin-bottom: 0"
            >
              <div class="img">
                <img
                  :src="this.$imgUrlFilter(item.product_img, this.imgWidth)"
                  alt=""
                />
              </div>
              <div class="info">
                <div class="cont">
                  <strong>
                    <div class="tedian">特典</div>
                    {{ item.product_name }}
                  </strong>
                  <p>{{ item.sku_specs }}</p>
                  <em class="time">{{ item.deliver_forecast_time }}</em>
                </div>
                <!-- 价格数量 -->
                <div class="price">
                  <p class="money" style="border: 0">¥{{ item.sku_price }}</p>
                  <p class="gray">×{{ item.num }}</p>
                </div>
              </div>
            </div>
          </template>
          <div class="steps-box">
            <div
              class="tips"
              v-if="
                orderInfo.presale_type == 2 && orderInfo.presale_pay_type == 1
              "
            >
              <span class="iconfont"> </span>
              若有优惠，优惠将在付尾款时使用
            </div>
            <div class="steps" v-if="item.steps && item.steps.length">
              <div class="steps-line">
                <div
                  :class="[
                    'top',
                    {
                      red: item.steps[0].light,
                    },
                  ]"
                ></div>
                <div
                  :class="[
                    'line',
                    {
                      line1: item.steps[0].light && !item.steps[1].light,
                      line2: !item.steps[0].light && item.steps[1].light,
                    },
                  ]"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div
                  :class="[
                    'bottom',
                    {
                      red: item.steps[1].light,
                    },
                  ]"
                ></div>
              </div>
              <div class="steps-li" v-for="(items, ix) in item.steps" :key="ix">
                <div :class="['steps-p', { red: item.light, nono: items.status_name==='未开始' }]">
                  <span class="step_title">{{ items.step_title }}</span>
                  {{ items.status_name }}
                  <div class="steps-time" v-if="items.time_describe">
                    {{ items.time_describe }}
                  </div>
                  <div class="right-price">¥{{ items.sku_amount }}</div>
                </div>
                <div class="steps-price" v-if="ix == 1">
                  <span>商品尾款</span>
                  <span>¥{{ items.sku_amount }}</span>
                </div>
                <div
                  class="steps-price"
                  v-if="items.seller_coupon_amount != '0.00'"
                >
                  <span>店铺优惠</span>
                  <span>-¥{{ items.seller_coupon_amount }}</span>
                </div>
                <div
                  class="steps-price"
                  v-if="items.max_reduction_coupon_amount != '0.00'"
                >
                  <span>跨店满减</span>
                  <span>-¥{{ items.max_reduction_coupon_amount }}</span>
                </div>
                <div
                  class="steps-price"
                  v-if="items.platform_coupon_amount != '0.00'"
                >
                  <span>平台优惠</span>
                  <span>-¥{{ items.platform_coupon_amount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <openCardlist
        v-if="orderInfo.card_info"
        :orderInfo="orderInfo"
        :orderId="orderInfo.order_id"
        :card_info="orderInfo.card_info"
      ></openCardlist>
    </div>
    <div class="conform">
      <!-- 运费 -->
      <div v-if="orderInfo.amount_info" class="freight-box">
        <div class="freight" v-for="(lineItem,lineIndex) in orderInfo.amount_info.amount_line" :key="lineIndex">
          <span>{{lineItem.title}}</span>
          <span>{{ lineItem.value }}</span>
        </div>
        <!-- 合计 -->
        <template v-if="orderInfo.amount_info && orderInfo.amount_info.total_amount_title">
          <div class="total">
            {{orderInfo.amount_info.total_amount_title}}
            <span>{{orderInfo.amount_info.total_amount_value}}</span>
          </div>
        </template>
      </div>
      <div class="remark" v-if="orderInfo.remark">
        <div class="left">
          <span>订单备注</span>
        </div>
        <div class="remarktext">{{ orderInfo.remark }}</div>
      </div>
      <div class="order_paper">
        <p class="freight">
          <span>订单号</span>
          <input
            ref="copy"
            style="position: absolute; opacity: 0; width: 1px; height: 1px"
            :value="orderInfo.order_id"
          />
          <span @click="copy" class="copyp" :copyData="orderInfo.order_id">
            {{ orderInfo.order_id }}
            <el-button class="fuzhi" type="" size="small" text bg>复制</el-button>
          </span>
        </p>
        <p class="freight">
          <span>下单时间</span>
          <span>{{ orderInfo.ctime }}</span>
        </p>
        <p
          class="freight"
          v-if="orderInfo.pay_time && orderInfo.presale_type != 2"
        >
          <span>付款时间</span>
          <span>{{ orderInfo.pay_time }}</span>
        </p>
        <p class="freight" v-if="orderInfo.first_pay_time">
          <span>定金支付时间</span>
          <span>{{ orderInfo.first_pay_time }}</span>
        </p>
        <p class="freight" v-if="orderInfo.tail_pay_time">
          <span>尾款支付时间</span>
          <span>{{ orderInfo.tail_pay_time }}</span>
        </p>
      </div>
    </div>
    <OpenApp ref="openapp" />
  </div>
</template>

<script>
import openCardlist from "@/components/order_detail/openCardlist";
import OpenApp from "@@/common/openApp.vue";
import domain from '@/service/domain'


export default {
  name: "goodsInfo",
  props: {
    orderInfo: Object,
  },
  data() {
    return {
      display_scale: "1x1",
    };
  },
  computed: {
    imgWidth() {
      return 80;
    },
    steptPirce() {
      const data = {
        step_pay_status_name: "",
        status_name: "",
        total_amount: 0,
        amount: 0,
      };
      const sku = this.orderInfo.shop.skus;
      if (sku && sku.length) {
        sku.forEach((e) => {
          if (e.steps && e.steps.length > 1) {
            data.amount += Number(e.steps[0].sku_amount);
            data.status_name = e.steps[0].status_name;
            data.step_pay_status_name = e.steps[1].step_pay_status_name;
            data.total_amount += Number(e.steps[1].total_amount);
          }
        });
      }

      return data;
    },
  },
  components: {
    openCardlist,
    OpenApp,
  },
  mounted() {},
  methods: {
    copy() {
      var text = this.$refs.copy;
      text.select(); // 选择对象
      document.execCommand("Copy");
      this.$message.success("复制成功");
    },
    openboxmore() {
      this.$emit("openboxmore");
    },
    //商品操作按钮
    // eslint-disable-next-line no-unused-vars
    op(type, oid, refund_id) {
      switch (type) {
        // 申请退款
        case "apply_refund":
          location.href = "/product/refund?soid=" + oid;
          break;
        // 申请售后
        case "apply_after":
          location.href = "/product/refund/apply?soid=" + oid;
          break;
        case "refund_success":
          break;
        default:
          location.href = "/product/refund?refund=" + refund_id;
      }
    },
    goShop() {



      if (process.env.VUE_APP_ENV == "" || process.env.VUE_APP_ENV == "pre") {
        window.open(
          `https://m.modian.com/product/pop/${this.orderInfo.shop.shop_id}.html`
        );
      } else {
        window.open(
          `https://${process.env.VUE_APP_ENV}m.modianinc.com/product/pop/${this.orderInfo.shop.shop_id}.html`
        );
      }
    },
    goGoods(id) {
      if (
        this.orderInfo.extract_card_type &&
        this.orderInfo.extract_card_type > 0
      ) {
        
          window.open(`${domain.wap}/luckycards/fastsell/detail/${this.orderInfo.extract_card_id}.html`)

        return false;
      } else {
        if (process.env.VUE_APP_ENV == "" || process.env.VUE_APP_ENV == "pre") {
          window.open(`https://m.modian.com/product/${id}.html`);
        } else {
          window.open(
            `https://${process.env.VUE_APP_ENV}m.modianinc.com/product/${id}.html`
          );
        }
      }
    },
    //客服按钮
    downApp() {},
  },
};
</script>
