<template>
  <div>
    <div class="nav">
      <div class="nav-1" @click="toList">我的订单</div>
      <div class="nav-icon"></div>
      <div class="nav-2">订单 {{ orderId }}</div>
    </div>
    <CompHeader title="订单详情" />
    <detailsHeader
      v-if="state.orderInfo"
      type="product"
      @openapp="appFn"
      :orderInfo="state.orderInfo"
    />
    <detailsAddress
      v-if="state.orderInfo"
      type="product"
      @openapp="appFn"
      :orderInfo="state.orderInfo"
    />
    <details-product-content
      v-if="state.orderInfo"
      :orderInfo="state.orderInfo"
      @openboxmore="openboxmore"
    />
  </div>
  <OpenApp ref="openapp" />

</template>

<script setup>
import {
  // eslint-disable-next-line no-unused-vars
  ref,
  // eslint-disable-next-line no-unused-vars
  watch,
  onMounted,
  reactive,
  // eslint-disable-next-line no-unused-vars
  defineExpose,
  // eslint-disable-next-line no-unused-vars
  computed,
  onBeforeUnmount,
} from "vue";
import OpenApp from "@@/common/openApp.vue";
// eslint-disable-next-line no-unused-vars
import { getMallOrderDetailV2 } from "@api";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import CompHeader from "@@/common/header.vue";
import { ElMessage } from "element-plus";
import detailsHeader from "@@/order_detail/detailsHeader.vue";
import detailsAddress from "@@/order_detail/detailsHeaderAddress.vue";
import DetailsProductContent from "@/components/order_detail/detailsProductContent.vue";

// eslint-disable-next-line no-unused-vars
const store = useStore();
const router = useRouter();
const route = useRoute();
let orderId = ref("");
const openapp = ref(null);
onMounted(() => {
  // console.log(openapp.value.open())
  orderId.value = route.query.order_id || "";
  getDetails();
});
const toList = () => {
  router.push('/order/order_list')
}
onBeforeUnmount(() => {});
const state = reactive({
  loading: true,
  list: [],
  orderInfo: null,
  page: 1,
  postCount: 0,
  nav: 100,
});
function appFn () {
  openapp.value.open()
}
const getDetails = async () => {
  try {
    const res = await getMallOrderDetailV2({
      order_id: orderId.value,
    });
    if (res.status !== 0) {
      return ElMessage({
        showClose: true,
        message: res.message,
        type: "error",
      });
    }
    state.orderInfo = res.data;
  } catch (e) {
    console.log(e);
  }
};
</script>
<style  lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  .nav-1 {
    font-size: 16px;
    font-weight: normal;
    color: #7f7f7f;
    cursor: pointer;

  }
  .nav-icon {
    margin: 0 5px;
    width: 16px;
    height: 16px;
    background: url("~@assets/images/orderlist/logoicon.png") center/cover
      no-repeat;
  }
  .nav-2 {
    font-size: 16px;
    color: #222c37;
    font-weight: bold;
  }
}
</style>
